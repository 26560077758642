<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",
  theme: {
    dark: true,
  },
  data: () => ({
    //
  })
};
</script>
