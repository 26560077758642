import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Public from "../views/Public.vue";
import Private from "../views/Private.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/public",
    component: Public,
    children: [
      {
        path: "/",
        name: "publicPage",
        component: () => import(/* webpackChunkName: "about" */ "../views/PublicPage.vue")
      }
    ]
  },
  {
    path: "/private",
    component: Private,
    meta: {
      nav: 'private'
    },
    children: [
      {
        path: "/",
        redirect: "start"
      },
      {
        path: "start",
        name: "startPage",
        component: () => import(/* webpackChunkName: "startPage" */ "../views/StartPage.vue"),
        meta: {
          navName: "Start Page",
          navIcon: "mdi-home"
        }
      },
      // {
      //   path: "feedback",
      //   name: "feedback",
      //   component: () => import(/* webpackChunkName: "feedback" */ "../views/Feedback.vue"),
      //   meta: {
      //     navName: "Feedback",
      //     navIcon: "mdi-comment-quote"
      //   }
      // },
      {
        path: "phonebook",
        name: "phonebook",
        component: () => import(/* webpackChunkName: "phonebook" */ "../views/Phonebook.vue"),
        meta: {
          navName: "Phonebook",
          navIcon: "mdi-account-box-outline"
        }
      },
      {
        path: "intercomstats",
        name: "intercomstats",
        component: () => import(/* webpackChunkName: "intercomstats" */ "../views/IntercomStats.vue"),
        meta: {
          navName: "Intercom Client Stats",
          navIcon: "mdi-account-box-outline"
        }
      },
      {
        path: "zerotier",
        name: "zerotier",
        component: () => import(/* webpackChunkName: "intercomstats" */ "../views/Zerotier.vue"),
        meta: {
          navName: "Zerotier",
          navIcon: "mdi-account-box-outline"
        }
      }
        // },
        // {
        //   path: "lb-internal-support",
        //   name: "lb-internal-support",
        //   component: () => import(/* webpackChunkName: "lb-internal-support" */ "../views/LBInternalSupport.vue"),
        //   meta: {
        //     navName: "LB Internal Support",
        //     navIcon: "mdi-account-box-outline"
        //   }
        // },
        // {
        //   path: "firestore",
        //   name: "firestoreTest",
        //   component: () => import(/* webpackChunkName: "firestoreTest" */ "../views/FirestoreTest.vue"),
        //   meta: {
        //     navName: "Firestore Test",
        //     navIcon: "mdi-lock"
        //   }
        // },
        // {
        //   path: "functions",
        //   name: "functionsTest",
        //   component: () => import(/* webpackChunkName: "functionsTest" */ "../views/FunctionsTest.vue"),
        //   meta: {
        //     navName: "Functions Test",
        //     navIcon: "mdi-home"
        //   }
        // },
        // {
        //   path: "statuspage",
        //   name: "statusPage",
        //   component: () => import(/* webpackChunkName: "statusPage" */ "../views/StatusPage.vue"),
        //   meta: {
        //     navName: "Status Page",
        //     navIcon: "mdi-account-box-outline"
        //   }
    ]
  },
];

export const privateNavItems = routes.find(a => a.meta && a.meta.nav && a.meta.nav === 'private').children.filter(a => a.meta && a.meta.navName)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
