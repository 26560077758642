import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyC61-8A5gf8qOmRhfny1F-ewZR8sh1R0TQ",
  authDomain: "leftbrain-apps.firebaseapp.com",
  databaseURL: "https://leftbrain-apps.firebaseio.com",
  projectId: "leftbrain-apps",
  storageBucket: "leftbrain-apps.appspot.com",
  messagingSenderId: "771500296500",
  appId: "1:771500296500:web:9bfdff5b3158a8e21bfd61"
};

firebase.initializeApp(firebaseConfig);
if (process.env.NODE_ENV === "development") firebase.functions().useFunctionsEmulator('http://localhost:5000')

export const auth = firebase.auth
export const firestore = firebase.firestore
export const functions = firebase.functions