<template>
  <v-container fluid class="fill-height">
    <v-row no-gutters align="center" justify="center" style="height:95vh">
      <v-col sm="6" md="4">
        <v-card>
          <v-card-title>
            Login
          </v-card-title>
          <v-card-text>
            <p>
              You must log in to view this page.
            </p>
            <v-btn @click="loginWithGoogle" text color="primary">Login With Google</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { auth } from "../firebase";

export default {
    methods: {
    loginWithGoogle() {
      const provider = new auth.GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account'
      })
      auth().signInWithRedirect(provider)
    }
  }
}
</script>