<template>
  <div>
    <Loading v-if="!authStateReady" />
    <div v-else>
      <LoginPanel v-if="!isLoggedIn" />
      <div v-else-if="!isLeftBrain">
        This page is restricted to LeftBrain staff.
      </div>
      <div v-else>
        <router-view :user="user"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "../firebase";
import LoginPanel from "../components/LoginPanel.vue";
import Loading from "../components/Loading.vue"

export default {
  data() {
    return {
      authStateReady: false,
      user: null
    };
  },
  computed: {
    isLoggedIn() {
      return Boolean(this.user);
    },
    isLeftBrain() {
      if (!this.isLoggedIn || !this.user.email || !this.user.emailVerified) return false
      const domain = this.user.email.split('@')[1]
      const leftbrainDomains = ['leftbrain.it', 'leftbra.in', 'leftbrain.ie']
      if (leftbrainDomains.indexOf(domain) === -1) return false
      else return true  
    }
  },
  components: {
    LoginPanel,
    Loading
  },
  created() {
    auth().onAuthStateChanged(user => {
      this.user = user;
      this.authStateReady = true;
    });
  }
};
</script>
